import React, { useState } from "react";
import "./advanced-configuration.css";
import goBackIcon from "../../../../assets/icons/go-back.svg";
import papeleraMovilImg from "../../../../assets/images/papelera.png"
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import { SettingsSectionTitle } from "../../../../components/settings-section-title/SettingsSectionTitle";
import { SettingsFunction } from "../../../../components/settings-function/SettingsFunction";
import { useDeleteEnterpriseMutation } from "../../../../features/enterprise/enterpriseApiSlice";

const AdvancedConfiguration = () => {
  const navigate = useNavigate();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [deleteEnterprise, { isLoading }] = useDeleteEnterpriseMutation();
  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);

  const handleDeleteAccount = () => {
    console.log("Enterprise deleted");
    deleteEnterprise();
    closeModal();
    navigate("/login"); 
  };

  return (
    <div className="advanced-configuration-movil">
      <img src={goBackIcon} alt="Go back" onClick={() => navigate("/home/profile")} />
      <SettingsSectionTitle label={"Configuración Avanzada"} />
      <SettingsFunction
        title={"Eliminar mi cuenta"}
        description={
          "Al eliminar tu cuenta, ya no podrás acceder a la información ingresada. Tu cuenta de amvi se creó a las 8:17 a. m. del 31 de julio de 2023."
        }
        buttonText={"Eliminar cuenta"}
        onClick={openModal}
      />
      <SettingsFunction
        title={"Reglas de viaje"}
        description={"Configura las reglas de viaje para tu empresa"}
        buttonText={"Configurar"}
        onClick={() => navigate("/home/profile/advanced-configuration/rules")}
      />
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className="modalContent"
        overlayClassName="modalOverlay"
        contentLabel="Confirmation Modal"
      >
        <img src={papeleraMovilImg} alt="Papelera" />
        <p>¿Estás seguro de eliminar tu cuenta?</p>
        <div className="modal-buttons">
          <button onClick={handleDeleteAccount}>Aceptar</button>
          <button onClick={closeModal}>Cancelar</button>
        </div>
      </Modal>
    </div>
  );
};

export { AdvancedConfiguration };