import React, { useRef, useState, useEffect } from "react";
import "./login.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import language from "../../../assets/icons/language.svg";
import { useLoginMutation } from "../../../features/auth/authApiSlice";
import { setAccessToken } from "../../../features/auth/authSlice";
import { setEmployee } from "../../../features/employee/employeeSlice";
import logo from "../../../assets/images/logo96px.png";
import logoHorizontal from "../../../assets/images/logo-horizontal.png";
// import { useCreateLogMutation } from "../../../features/log/logApiSlice";

const Login = () => {
  const navigation = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [login, { isLoading }] = useLoginMutation();
  const [error, setError] = useState("");
  // const [createLog] = useCreateLogMutation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const userData = await login({
        username: credentials.emailOrPhone,
        password: credentials.password,
      }).unwrap();
      dispatch(setAccessToken(userData.token));
      dispatch(setEmployee(userData.user.idEmployee));
      // Log successful login
      // <Log userId={userData.user.idEmployee} action="login" result="success" />;
      // useCreateLogMutation(userData.user.idUser, "Login", "Exito");
      navigation("/home");
    } catch (err) {
      // TODO get the id of the user trying to get accessed
      // useCreateLogMutation(userId, "Login", "Error");
      if (err.status === 401) {
        setError("Invalid credentials. Please try again.");
      } else {
        setError("An unexpected error occurred. Please try again later.");
      }
    }
  };

  const [credentials, setCredentials] = useState({
    emailOrPhone: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCredentials({
      ...credentials,
      [name]: value,
    });
  };

  const divRef = useRef(null);
  const [divHeight, setDivHeight] = useState(0);

  useEffect(() => {
    // Obtén la altura del div cuando se renderice
    if (divRef.current) {
      setDivHeight(divRef.current.offsetHeight);
    }

    // Escucha cambios de tamaño si es necesario
    const handleResize = () => {
      if (divRef.current) {
        setDivHeight(divRef.current.offsetHeight);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [divHeight]);

  const destination = location.pathname === "/login" ? "/" : "/home";

  return (
    <>
      <div className="title-form-login account-header">
        <img
          style={{ maxHeight: "22vh", cursor: "pointer" }}
          src={logo}
          alt="amvi"
        />
      </div>
      <div className="login-form-container">
        <h2>Iniciar Sesión</h2>
        {error && <div className="error-message">{error}</div>}
        <form onSubmit={handleSubmit}>
          <div className="form-group-login">
            <label htmlFor="email">Correo o Teléfono</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="form-input"
              required
            />
          </div>
          <div className="form-group-login">
            <label htmlFor="password">Contraseña</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="form-input"
              required
            />
            <p className="forgot-password-link">
              <span
                onClick={() => {
                  navigation("/forgot-password");
                }}
              >
                Restablecer contraseña
              </span>
            </p>
          </div>
          <button type="submit" className="form-button">
            Iniciar Sesión
          </button>
        </form>
      </div>
      <div className="login-desktop">
        <div className="header-login-desktop">
          <div className="brand-login-desktop">
            <img
              style={{ maxWidth: "4vw" }}
              src={logo}
              alt="amvi"
              onClick={() => navigation(destination)}
            />
          </div>
          <div className="language-login-desktop">
            <img src={language} alt="" />
            <label>Es</label>
          </div>
        </div>
        <div className="login-image-desktop" ref={divRef}>
          <div className="brand-form-desktop">
            {divHeight <= 600 ? (
              <img src={logoHorizontal} alt="amvi-horizontal" />
            ) : (
              <img style={{ maxHeight: "22vh" }} src={logo} alt="amvi" />
            )}
          </div>
          <form onSubmit={handleSubmit} className="form-desktop">
            <h2>Iniciar Sesión</h2>

            <div className="formGroup-login-desktop">
              <label htmlFor="emailOrPhone">Correo o Teléfono</label>
              <input
                type="text"
                id="emailOrPhone"
                name="emailOrPhone"
                value={credentials.emailOrPhone}
                onChange={handleChange}
                required
              />
            </div>

            <div className="formGroup-login-desktop">
              <label htmlFor="password">Contraseña</label>
              <input
                type="password"
                id="password"
                name="password"
                value={credentials.password}
                onChange={handleChange}
                required
              />
            </div>

            <div className="forgot-password-desktop">
              <a href onClick={() => navigation("/forgot-password")}>
                Reestablecer contraseña
              </a>
            </div>
            <button type="submit" className="form-button" disabled={isLoading}>
              {isLoading ? "Iniciando sesión..." : "Iniciar Sesión"}
            </button>
            <button onClick={() => navigation("/sign-up")} className="form-button" disabled={isLoading}>
              Registrarse
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export { Login };
