import React, { useState, useEffect } from "react";
import "./profile.css";
import camera from "../../../assets/icons/camera-outline.svg";
import goBackIcon from "../../../assets/icons/go-back.svg";
import editIcon from "../../../assets/icons/create-outline.svg";
import { MobileActionButton } from "../../../components/mobile-action-button/MobileActionButton";
import { MobileSubscritptionInput } from "../../../components/mobile-subscription-input/MobileSubscriptionInput";
import { BrowserLabel } from "../../../components/browser-label/BrowserLabel";
import { useNavigate } from "react-router-dom";
import { GreenBorderButton } from "../../../components/green-border-button/GreenBorderButton";
import cameraIcon from "../../../assets/icons/camera-outline.svg";
import { ItemShowData } from "../../../components/item-show-data/ItemShowData";
import {
  useGetEnterpriseProfileQuery,
  useUpdateEnterpriseProfileMutation,
} from "../../../features/enterprise/enterpriseApiSlice";
import texts from "../../../texts.json";
import timeZones from "./timeZone.json";

const Profile = () => {
  const textsProfile = texts.views.profile;
  const [edit, setEdit] = useState(false);
  const [editingField, setEditingField] = useState(null);
  const [formData, setFormData] = useState({});
  const navigate = useNavigate();
  const {
    data: enterpriseData,
    isLoading,
    error,
  } = useGetEnterpriseProfileQuery();
  const [updateProfile] = useUpdateEnterpriseProfileMutation();

  const [selectedTimezone, setSelectedTimezone] = useState("");
  const [timezones, setTimezones] = useState([]);

  useEffect(() => {
    // Si está en un archivo local
    // fetch("./timeZone.json")
    //  .then((response) => response.json())
    //  .then((data) => setTimezones(data))
    //  .catch((error) => console.error("Error al cargar las zonas horarias:", error));

    // Si usas directamente el JSON como objeto
    setTimezones(timeZones);
  }, []);

  const handleChangeTimeZone = (event) => {
    setSelectedTimezone(event.target.value);
    console.log("Zona horaria seleccionada:", event.target.value);
  };

  const handleInputChange = (e, field) => {
    const value = e.target.value;
    setFormData((prev) => {
      const newData = {
        ...prev,
        [field]: value,
      };
      return newData;
    });
  };

  const VALID_LANGUAGES = ["spanish", "english", "deutsch"];
  const VALID_APPEARANCES = ["light", "dark"];
  const handleSave = async (field) => {
    try {
      let updateData = {};

      // Validate required fields
      if (!formData[field]) {
        console.error("Field value is required");
        return;
      }

      // Handle different field types
      switch (field) {
        case "name":
          if (formData[field].trim().length < 1) {
            console.error("Name cannot be empty");
            return;
          }
          updateData = {
            name: formData[field].trim(),
          };
          break;

        case "email":
          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          if (!emailRegex.test(formData[field])) {
            console.error("Invalid email format");
            return;
          }
          updateData = {
            email: formData[field].toLowerCase(),
          };
          break;

        case "address":
          if (formData[field].trim().length < 1) {
            console.error("Address cannot be empty");
            return;
          }
          updateData = {
            contact: {
              ...enterpriseData?.data?.contact,
              address: formData[field].trim(),
            },
          };
          break;

        case "language":
          if (!VALID_LANGUAGES.includes(formData[field])) {
            console.error(
              "Invalid language. Valid options are:",
              VALID_LANGUAGES
            );
            return;
          }
          updateData = {
            language: formData[field],
          };
          break;

        case "appearance":
          if (!VALID_APPEARANCES.includes(formData[field])) {
            console.error(
              "Invalid appearance. Valid options are:",
              VALID_APPEARANCES
            );
            return;
          }
          updateData = {
            appearance: formData[field],
          };
          break;

        case "timeZone":
          console.log("timeZone");

          break;

        default:
          updateData = {
            [field]: formData[field],
          };
      }

      const result = await updateProfile(updateData).unwrap();

      if (result.success) {
        setEditingField(null);
        setFormData({});
        // Could add a success message here
      } else {
        console.error("Update failed:", result.message);
        // Could show error message to user
      }
    } catch (err) {
      console.error("Failed to update:", err);
      // Could show error message to user
      if (err.status === 400) {
        // Handle validation errors
        console.error("Validation error:", err.data?.message);
      } else {
        // Handle other errors
        console.error("Server error:", err.message);
      }
    }
  };

  const getFullAddress = () => {
    if (!enterpriseData?.data?.contact) return "Sin dirección";

    const contact = enterpriseData.data.contact;
    const addressParts = [
      contact.street,
      contact.buildingNumber,
      contact.city,
      contact.state,
      contact.postalCode,
      contact.country,
    ].filter(Boolean);

    return addressParts.length > 0 ? addressParts.join(", ") : "Sin dirección";
  };


  const renderInputField = (field, value) => {
    switch (field) {
      case "language":
        return (
          <select
            value={value || ""}
            onChange={(e) => handleInputChange(e, field)}
          >
            <option value="spanish">{textsProfile.spanishLanguage}</option>
            <option value="english">{textsProfile.englishLanguage}</option>
            <option value="deutsch">{textsProfile.germanLanguage}</option>
          </select>
        );

        case "appearance":
          return (
            <select
              value={value || ""}
              onChange={(e) => handleInputChange(e, field)}
            >
              <option value="light">{textsProfile.ligthModeOptionText}</option>
              <option value="dark">{textsProfile.darkModeOptionText}</option>
            </select>
          );

      case "timeZone":
        return (
          <select
            id="timezone-select"
            value={selectedTimezone}
            onChange={handleChangeTimeZone}
          >
            <option value="">-- Seleccionar --</option>
            {timezones.map((tz, index) => (
              <option key={index} value={tz.timezone}>
                {tz.timezone} - {tz.region}
              </option>
            ))}
          </select>
        );

      default:
        return (
          <input
            type="text"
            value={value || ""}
            onChange={(e) => handleInputChange(e, field)}
            style={{ width: "100%", padding: "8px" }}
          />
        );
    }
  };


  const EditableField = ({ label, value, field }) => {
    return editingField === field ? (
      <div className="data-container-button">
        <ItemShowData
          label={label}
          value={
            <div className="input-container-edit-profile">
              {renderInputField(field, formData[field])}
            </div>
          }
        />
        <div className="edit-profile-button-container">
          <GreenBorderButton
            text={"Guardar"}
            border={true}
            onClick={() => {
              console.log("Saving with data:", formData[field]); // Debug log
              handleSave(field);
            }}
          />
          <GreenBorderButton
            text={"Cancelar"}
            border={false}
            onClick={() => {
              setEditingField(null);
              setFormData({});
            }}
          />
        </div>
      </div>
    ) : (
      <div className="data-container-button">
        {field === "language" ? (
          <ItemShowData
            label={label}
            value={
              value === "spanish"
                ? textsProfile.spanishLanguage
                : value === "english"
                ? textsProfile.englishLanguage
                : value === "deutsch"
                ? textsProfile.germanLanguage
                : value
            }
          />
        ) : field === "appearance" ? (
          <ItemShowData
            label={label}
            value={
              value === "light"
                ? textsProfile.ligthModeOptionText
                : value === "dark"
                ? textsProfile.darkModeOptionText
                : value
            }
          />
        ) : (
          <ItemShowData label={label} value={value} />
        )}
        <GreenBorderButton
          text={"Editar"}
          border={true}
          onClick={() => {
            console.log("Starting edit with value:", value); // Debug log
            setEditingField(field);
            setFormData({ [field]: value });
          }}
        />
      </div>
    );
  };
  
  if (isLoading) return <div>Cargando...</div>;
  if (error) return <div>Error al cargar los datos</div>;

  return (
    <>
      <div className="deskSubs-general">
        <div className="photo-button-container">
          <div className="blue-camera-icon-container">
            <img src={cameraIcon} alt="" />
          </div>
          <div className="button-container-to-change-profile-picture">
            <GreenBorderButton
              text={textsProfile.changePhotoButtonText}
              border={true}
            />
            <GreenBorderButton
              text={textsProfile.removePhotoButtonText}
              border={false}
            />
          </div>
        </div>
        <div className="company-data-list-container">
        <EditableField
    label={textsProfile.inputLabelName}
    value={enterpriseData?.data?.name}
    field="name"
  />

  <EditableField
    label={textsProfile.inputLabelEmail}
    value={enterpriseData?.data?.email}
    field="email"
  />

  <EditableField
    label={textsProfile.companyAddressInputLabel}
    value={getFullAddress()}
    field="address"
  />

  <EditableField
    label={textsProfile.languageInputLabel}
    value={enterpriseData?.data?.language}
    field="language"
  />

  <EditableField
    label={textsProfile.appearanceInputLabel}
    value={enterpriseData?.data?.appearance}
    field="appearance"

  />

          <EditableField
            label={textsProfile.timeZoneInputLabel}
            value={"UTC -6"}
            field="timeZone"
          />
        </div>
        <div className="advanced-options-container">
          <BrowserLabel
            label={textsProfile.loginAndSecurityLabel}
            navigateTo={"/home/profile/login-and-security"}
          />
          <div className="container-with-grey-edges">
            <BrowserLabel
              label={textsProfile.advancedConfigurationLabel}
              navigateTo={"/home/profile/advanced-configuration"}
            />
          </div>
          <BrowserLabel
            label={textsProfile.travelRulesLabel}
            navigateTo={"/home/profile/trip-rules"}
          />
        </div>
      </div>
      <div className="general-container-profile">
        {!edit && (
          <>
            <div className="navigation-option-profile">
              <img src={goBackIcon} alt="" onClick={() => navigate("/home")} />
              <img src={editIcon} alt="" onClick={() => setEdit(true)} />
            </div>
            <section className="profile-general-information">
              <div className="profile-picture">
                <div>
                  <img src={camera} alt="" />
                </div>
              </div>
            </section>
            <EditableField
              label="Nombre"
              value={enterpriseData?.data?.name}
              field="name"
            />

            <EditableField
              label="Correo Electrónico"
              value={enterpriseData?.data?.email}
              field="email"
            />

            <EditableField
              label="Dirección de la Empresa"
              value={getFullAddress()}
              field="address"
            />

            <EditableField
              label="Idioma"
              value={enterpriseData?.data?.language}
              field="language"
            />
          <EditableField
            label={"Apariencia"}
            value={enterpriseData?.data?.appearance}
            field="appearance"
          />
            <div className="browser-tag-container">
              <BrowserLabel
                label={"Inicio de Sesión y Seguridad"}
                navigateTo={"/home/profile/login-and-security"}
              />
              <BrowserLabel
                label={"Configuración Avanzada"}
                navigateTo={"/home/profile/advanced-configuration"}
              />
              <BrowserLabel
                label={"Mis Pagos"}
                navigateTo={"/home/profile/my-payments"}
              />
            </div>
          </>
        )}
        {edit && (
          <>
            <section className="profile-general-information">
              <div className="profile-picture">
                <div></div>
              </div>
            </section>
            <section className="form-profile">
              <MobileSubscritptionInput label={"Nombre de la Empresa"} />
              <MobileSubscritptionInput label={"Direccion"} />
              <MobileSubscritptionInput label={"RFC"} />
              <MobileSubscritptionInput label={"Idioma"} />
            </section>
            <section>
              <MobileActionButton text={"Cancelar"} cancel={true} />
              <MobileActionButton
                text={"Guardar"}
                onClick={() => setEdit(false)}
              />
            </section>
          </>
        )}
      </div>
    </>
  );
};

export { Profile };
