// apiSlice.js
// Configuración base de la API y manejo de autenticación
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { setAccessToken, logOut } from '../features/auth/authSlice';

const baseQuery = fetchBaseQuery({
  // baseUrl: '18.221.235.194:8084/api', // 3023
  // baseUrl: 'http://localhost:3000/api', // 3023

  
  baseUrl: 'https://api.amvi.app/api',
  credentials: 'include',
  prepareHeaders: (headers, { getState }) => {
    const token = getState().auth.accessToken;
    if (token) {
      headers.set('authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  console.log("📡 Enviando solicitud:", args);

  let result = await baseQuery(args, api, extraOptions);
  console.log("✅ Respuesta recibida:", result);

  if (result?.error) {
    console.error("❌ Error en la solicitud:", result.error);
  }

  if (result?.error?.status === 403) {
    console.warn("🔄 Token expirado, intentando refrescar...");
    const refreshResult = await baseQuery(
      { url: 'auth/token', method: 'POST' },
      api,
      extraOptions
    );

    console.log("🔄 Respuesta del refresh token:", refreshResult);

    if (refreshResult.data) {
      console.log("🔑 Nuevo token recibido, actualizando...");
      api.dispatch(setAccessToken(refreshResult.data.accessToken));
      result = await baseQuery(args, api, extraOptions);
    } else {
      console.warn("⚠️ No se pudo refrescar el token, cerrando sesión...");
      await baseQuery(
        { url: 'auth/logout', method: 'POST' },
        api,
        extraOptions
      );
      api.dispatch(logOut());
    }
  }

  return result;
};

export const apiSlice = createApi({
  baseQuery: baseQueryWithReauth,
  tagTypes: ['Enterprise', 'User', 'Employee'],
  endpoints: (builder) => ({}),
});
