import { React, useState } from "react";
import "./payment-method.css";
import createNewIcon from "../../assets/icons/add-circle.svg";
import emptyImage from "../../assets/images/empty.png";
import cardIcon from "../../assets/icons/wallet-outline.svg";
import rightIcon from "../../assets/icons/right.svg";
import useWindowWidth from "../../hooks/useWindowWidth.js";
import { useNavigate, useLocation } from "react-router-dom";
import { ViewTitleAndBackButton } from "../../components/view-title-and-back-button/ViewTitleAndBackButton.js";
import { MobileActionButton } from "../../components/mobile-action-button/MobileActionButton.js";
import {
  useGetCardsQuery,
  useCreateSetupIntentMutation,
  useSetDefaultPaymentMethodMutation,
} from "../../features/stripe/stripeApiSlice.js";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { PaymentForm } from "../../components/payment-form/PaymentForm.js";

const stripePromise = loadStripe(
  "pk_test_51QK7dpA7xhn46tY572yofv0dyomPk5FVdyLC3BSwicCkzC7mMgVnKocWpl9V0J08b4sY3pGzsBGqXVABHkyIa2fV00jlkMXkOa"
);

const PaymentMethod = () => {
  const windowWidth = useWindowWidth();
  const navigate = useNavigate();
  const location = useLocation();

  //Get state props passed
  const { plan_name, priceId, productId, subscription, validateSubscription } =
    location.state || {};

  //If true renders PaymentForm
  const [isAddingPaymentMethod, setIsAddingPaymentMethod] = useState(false);

  //Set clientSecret state to render PaymentForm
  const [clientSecret, setClientSecret] = useState(null);

  //Fetch all enterprise's card details.
  const { data: cards, isLoading } = useGetCardsQuery();
  console.log(cards);
  //Setup intent (save payment method validated by stripe).
  const [createSetupIntent] = useCreateSetupIntentMutation();

  //Set default payment method when selecting
  const [setDefaultPaymentMethod] = useSetDefaultPaymentMethodMutation();

  //Formatting brands name
  const getBrandName = (brand) => {
    const brands = {
      visa: "Visa",
      mastercard: "MasterCard",
      amex: "American Express",
      discover: "Discover",
    };
    return brands[brand.toLowerCase()] || brand;
  };
  // Format expiration dates as MM/YY
  const formatExpDate = (month, year) => {
    return `${String(month).padStart(2, "0")}/${String(year).slice(-2)}`;
  };

  //Change state to render PaymentForm and call setUpIntent mutation
  const handleAddPaymentMethod = async () => {
    try {
      setIsAddingPaymentMethod(true);
      const response = await createSetupIntent().unwrap(); // call mutation to SetupIntent
      setClientSecret(response.clientSecret);
      console.log(
        "Setup Intent created with clientSecret:",
        response.clientSecret
      );
    } catch (error) {
      console.error("Error creating SetupIntent:", error);
      alert("Error al crear el SetupIntent.");
    }
  };

  // Handle logic for selecting a payment method
  const handleSelectPaymentMethod = async (cardId) => {
    try {
      // Wait for the payment method to be set
      await setDefaultPaymentMethod(cardId);

      if (!subscription) {
        // If a subscription exists, navigate to the purchase details page
        navigate("/home/purchase-detail", {
          state: {
            paymentMethodId: cardId,
            plan_name,
            priceId,
            validateSubscription,
            productId,
          },
        });
      } else {
        // If no subscription exists, navigate to the subscriptions page
        navigate("/subscriptions");
      }
    } catch (error) {
      console.error("Error setting default payment method:", error);
      alert("Failed to set default payment method. Please try again.");
    }
  };

  //If there is not elements on card data "No hay método de pago guardado" will be displayed.
  const isEmpty = !cards?.data?.length;

  if (isLoading) {
    return <div>Cargando...</div>;
  }

  return (
    <>
      {!isAddingPaymentMethod ? (
        <>
          {/* Desktop View */}
          <div
            className="view-payment-method"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              // alignItems: "center",
              width: windowWidth >= 1200 ? "70%" : "100%",
              top: isEmpty ? "43%" : "10%",
              
            }}
          >
            <div className="header-and-create-new-button">
              <label>Método de Pago</label>
              <div
                className="create-new-button"
                onClick={handleAddPaymentMethod}
              >
                <img src={createNewIcon} alt="create new" />
                <label>Crear Nuevo</label>
              </div>
            </div>
            <div>
              {isEmpty ? (
                <div className="empty-payment-method">
                  <img src={emptyImage} alt="empty" />
                  <p>No hay método de pago guardado</p>
                </div>
              ) : (
                cards.data.map((card) => (
                  <div className="card-payment-method" key={card.id}>
                    <div className="payment-card-data">
                      <img src={cardIcon} alt="" />
                      <div>
                        <label>
                          <strong>{getBrandName(card.brand)} |</strong>
                          {card.holderName || "Titular no especificado"}
                          {card.isDefault && " (Principal)"}
                        </label>
                        <div className="card-number-and-expiration-date">
                          <span>.... {card.last4}</span>
                          <span>
                            {formatExpDate(card.expMonth, card.expYear)}
                          </span>
                        </div>
                      </div>
                    </div>
                    <button onClick={() => handleSelectPaymentMethod(card.id)}>
                      Seleccionar
                    </button>
                  </div>
                ))
              )}
            </div>
          </div>

          {/* Mobile View */}
          <div
            className="view-mobile-payment-method"
            style={{ height: isEmpty ? "" : "85vh" }}
          >
            <ViewTitleAndBackButton title={"Método de Pago"} />
            {isEmpty ? (
              <div className="empty-payment-method-on-mobile">
                <img src={emptyImage} alt="empty" />
                <p>No hay método de pago guardado</p>
              </div>
            ) : (
              cards.data.map((card) => (
                <div className="mobile-card-payment-method" key={card.id}>
                  <div className="card-icon-container-for-mobile-payment-method">
                    <img src={cardIcon} alt="cardIcon" />
                  </div>
                  <div className="mobile-payment-method-information">
                    <label>{getBrandName(card.brand)}</label>
                    <div
                      className="payment-card-holder"
                      onClick={() =>
                        navigate("/home/purchase-detail", {
                          state: {
                            paymentMethodId: card.id,
                            plan_name,
                            priceId,
                          },
                        })
                      }
                    >
                      <label>
                        {card.holderName || "Titular no especificado"}
                      </label>
                      <img src={rightIcon} alt="right arrow" />
                    </div>
                    <div
                      className="mobile-payment-method-card-number-and-expiration-date"
                      onClick={() =>
                        navigate("/home/purchase-detail", {
                          state: {
                            paymentMethodId: card.id,
                            plan_name,
                            priceId,
                          },
                        })
                      }
                    >
                      <label>.... {card.last4}</label>
                      <label>
                        {formatExpDate(card.expMonth, card.expYear)}
                      </label>
                    </div>
                    <button className="delete-payment-method-button-on-mobile">
                      Eliminar
                    </button>
                  </div>
                </div>
              ))
            )}
            <div
              style={{
                position: isEmpty ? "" : "absolute",
                bottom: isEmpty ? "" : 0,
                display: isEmpty ? "" : "flex",
                justifyContent: isEmpty ? "" : "center",
                alignItems: isEmpty ? "" : "center",
                width: isEmpty ? "" : "85%",
              }}
            >
              <MobileActionButton
                text={"Nuevo"}
                onClick={() => navigate("/home/new-payment-method")}
              />
            </div>
          </div>
        </>
      ) : (
        clientSecret && (
          <Elements stripe={stripePromise} options={{ clientSecret }}>
            <PaymentForm
              clientSecret={clientSecret}
              planName={plan_name}
              priceId={priceId}
            />
          </Elements>
        )
      )}
    </>
  );
};

export { PaymentMethod };
