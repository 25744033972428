import React, { useState, useEffect } from "react";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import "./payment-form.css";

const PaymentForm = ({ clientSecret, planName, priceId }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [name, setName] = useState("");
  console.log(name);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isReady, setIsReady] = useState(false);

  const queryParams = new URLSearchParams({
    plan_name: planName, // Prop recibida en el componente
    priceId: priceId, // Prop recibida en el componente
  });

  useEffect(() => {
    if (stripe && elements) {
      setIsReady(true);
    }
  }, [stripe, elements]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements || !isReady) {
      alert("Stripe.js is not ready yet. Please wait a moment and try again.");
      return;
    }

    setIsProcessing(true);

    try {
      const { setupIntent, error } = await stripe.confirmSetup({
        elements,
        confirmParams: {
          return_url: `https://amvi.app/home/purchase-detail?${queryParams.toString()}`,
        },
        payment_method_data: {
          billing_details: {
            name: name,
          },
        },
      });
      if (error) {
        console.error("Stripe Error:", error.message);
        alert(`Error: ${error.message}`);
        setIsProcessing(false);
        return;
      }

      alert("Payment method added successfully!");
    } catch (err) {
      console.error("Error confirming setup intent:", err);
      alert("Failed to add payment method. Please try again.");
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="payment-form">
      <h4>Método de Pago</h4>

      <div className="form-group">
        <PaymentElement />
        {/* Input field for cardholder's name */}
        <div className="form-group">
          <label htmlFor="name" className="stripe-label">
            Nombre del titular
          </label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            className="stripe-input"
          />
        </div>
      </div>
      {/* Input field for cardholder's name */}

      <button
        type="submit"
        disabled={!isReady || isProcessing}
        className="submit-button"
      >
        {isProcessing ? "Procesando..." : "Guardar Método de Pago"}
      </button>
    </form>
  );
};

export { PaymentForm };
