// employeeApiSlice.js
import { apiSlice } from '../../api/apiSlice';

export const employeeApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    RegisterEmployee: builder.mutation({
      query: (body) => ({
        url: '/employee/register',
        method: 'POST',
        body: body,
      }),
      invalidatesTags: ['Employee'],
    }),
    getEmployeeRole: builder.query({
      query: () => ({
        url: '/employee/role',
        method: 'GET',
      }),
      providesTags: ['Employee'],
    }),
    updateEmployeeRole: builder.mutation({
      query: (data) => ({
        url: '/employee/role',
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['Employee'],
    }),
    softDeleteEmployee: builder.mutation({
      // Define the API endpoint and method
      query: (id) => ({
        url: `/employee/soft-delete/${id}`, // Endpoint for soft deleting an employee
        method: 'PATCH', // HTTP method for updating the employee
      }),
      // Invalidate the 'Employee' tag to refresh relevant queries
      invalidatesTags: ['Employee'],
    }),
  })
});

export const {
  useRegisterEmployeeMutation,
  useGetEmployeeRoleQuery,
  useUpdateEmployeeRoleMutation,
  useSoftDeleteEmployeeMutation,
} = employeeApiSlice;
